// Generated by Framer (7699a62)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useConstant, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["B0KwqjjBO", "HRGZeOGtp", "abBrrfH1S"];

const serializationHash = "framer-PHd8q"

const variantClassNames = {abBrrfH1S: "framer-v-4qqq56", B0KwqjjBO: "framer-v-573uq6", HRGZeOGtp: "framer-v-purkga"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "B0KwqjjBO", "Variant 2": "HRGZeOGtp", "Variant 3": "abBrrfH1S"}

const getProps = ({height, hover, id, tap, title, width, ...props}) => { return {...props, aQinvpOzj: title ?? props.aQinvpOzj ?? "Služby", FIxGQBS1M: hover ?? props.FIxGQBS1M, JvTXPiUAp: tap ?? props.JvTXPiUAp, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "B0KwqjjBO"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;tap?: any;hover?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, aQinvpOzj, JvTXPiUAp, FIxGQBS1M, ...restProps} = getProps(props)

const initialVariant = useConstant(() => variant)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "B0KwqjjBO", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap155zrzp = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (JvTXPiUAp) {
const res = await JvTXPiUAp(...args);
if (res === false) return false;
}
})

const onMouseEnterrzpxn0 = activeVariantCallback(async (...args) => {
if (FIxGQBS1M) {
const res = await FIxGQBS1M(...args);
if (res === false) return false;
}
setVariant("HRGZeOGtp")
})

const onTapg2st5o = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (JvTXPiUAp) {
const res = await JvTXPiUAp(...args);
if (res === false) return false;
}
setVariant("abBrrfH1S")
})

const onMouseLeave188lz89 = activeVariantCallback(async (...args) => {
setVariant("abBrrfH1S")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link {...addPropertyOverrides({HRGZeOGtp: {href: {webPageId: "P_7SMygvg"}, openInNewTab: false}}, baseVariant, gestureVariant)}><motion.a {...restProps} animate={variants} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-573uq6", className, classNames)} framer-1v4tubc`} data-framer-name={"Variant 1"} data-highlight initial={initialVariant} layoutDependency={layoutDependency} layoutId={"B0KwqjjBO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterrzpxn0} onTap={onTap155zrzp} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({abBrrfH1S: {"data-framer-name": "Variant 3"}, HRGZeOGtp: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeave188lz89, onTap: onTapg2st5o}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Služby</motion.p></React.Fragment>} className={"framer-1sate1j"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"E8qpNNqKz"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", textShadow: "none"}} text={aQinvpOzj} transformTemplate={transformTemplate1} variants={{HRGZeOGtp: {textShadow: "0px 0px 7px #FFFFFF"}}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-PHd8q[data-border=\"true\"]::after, .framer-PHd8q [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PHd8q.framer-1v4tubc, .framer-PHd8q .framer-1v4tubc { display: block; }", ".framer-PHd8q.framer-573uq6 { cursor: pointer; height: 24px; overflow: visible; position: relative; width: 51px; }", ".framer-PHd8q .framer-1sate1j { flex: none; height: auto; left: 49%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }", ".framer-PHd8q.framer-v-purkga.framer-573uq6 { text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 51
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HRGZeOGtp":{"layout":["fixed","fixed"]},"abBrrfH1S":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"aQinvpOzj":"title","JvTXPiUAp":"tap","FIxGQBS1M":"hover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerGXlbPzU7i: React.ComponentType<Props> = withCSS(Component, css, "framer-PHd8q") as typeof Component;
export default FramerGXlbPzU7i;

FramerGXlbPzU7i.displayName = "Služby";

FramerGXlbPzU7i.defaultProps = {height: 24, width: 51};

addPropertyControls(FramerGXlbPzU7i, {variant: {options: ["B0KwqjjBO", "HRGZeOGtp", "abBrrfH1S"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, aQinvpOzj: {defaultValue: "Služby", displayTextArea: false, title: "Title", type: ControlType.String}, JvTXPiUAp: {title: "Tap", type: ControlType.EventHandler}, FIxGQBS1M: {title: "Hover", type: ControlType.EventHandler}} as any)

addFonts(FramerGXlbPzU7i, [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZ1rib2Bg-4.woff2", weight: "500"}])